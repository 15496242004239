<template>
  <div class="contatain">
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="payList"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          v-loading="loading"
        >
          <el-table-column prop="name" label="用户名"> </el-table-column>
          <el-table-column prop="createTime" label="付费时间"> </el-table-column>
          <el-table-column prop="price" label="金额"></el-table-column>
          <el-table-column label="剧本名" prop="scenrio"> </el-table-column>
          <el-table-column prop="result" label="付费结果"></el-table-column>
        </el-table>
        <!-- <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination> -->
      </el-card>
    </div>
  </div>
</template>
<script>
import { timestampToDate } from "@tools";
export default {
  components: {},
  data() {
    return {
      loading: false,
      payList: [
          {
              price: '99人民币',
              createTime: '2021-5-20 08:59',
              result: '购买成功',
              name: '北边二哈',
              scenrio: '神探社'
          },
          {
              price: '299人民币',
              createTime: '2021-5-28 15:32',
              result: '购买成功',
              name: '三心挖掘机',
              scenrio: '神都百鬼行'
          },
          {
              price: '199人民币',
              createTime: '2021-6-10 10:59',
              result: '购买成功',
              name: '全神',
              scenrio: '阿全的人生'
          }
      ],
      params: {
        limit: 10,
        page: 1,
      },
      count: 0,
      editForm: null,
      actionArr: [
      ],
    };
  },
  async created() {
  },
  mounted() {},
  methods: {
    async getAdminAction() {
      this.loading = true;
      const { data, count } = await this.$api.log
        .getAdminAction(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.actionArr = data;
      this.count = count;
    },
    handleSizeChange(size) {
      this.params.limit = size;
      this.getAdminAction();
    },
    handlePageChange(page) {
      this.params.page = page;
      this.getAdminAction();
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        const { dictName, dictType, id, remark } = form;
        this.editForm = { dictName, dictType, id, remark };
      } else {
        this.editForm = null;
      }
      this.$refs[ref].showDialog = true;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
</style>